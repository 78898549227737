import React from 'react'
import { graphql } from 'gatsby'

import Layout from '~/components/layout'
import SEO from '~/components/seo'
import Section from '~/components/section'
import Gallery from '~/components/gallery'

const IndexPage = ({ data: { allStrapiHome, allStrapiMeilleursLots } }) => {
  const homeData = allStrapiHome.nodes[0].body
  const bestOfTitle = allStrapiHome.nodes[0].best.title
  const bestOfData = allStrapiMeilleursLots.nodes

  return (
    <Layout>
      <SEO />
      {homeData.map((el, index, arr) => {
        return (
          <div key={index}>
            {index === 1 && <Gallery title={bestOfTitle} list={bestOfData} />}
            <Section data={el} index={index} length={arr.length} />
          </div>
        )
      })}
    </Layout>
  )
}

export const query = graphql`
  query homeQuery {
    allStrapiHome(filter: { locale: { eq: "fr-FR" } }) {
      nodes {
        body
        best {
          title
        }
      }
    }
    allStrapiMeilleursLots(
      sort: { order: DESC, fields: date }
      filter: { locale: { eq: "fr-FR" } }
    ) {
      nodes {
        photo {
          url
        }
        price
        title
      }
    }
  }
`

export default IndexPage
